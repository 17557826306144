  .layout {
    /* background: radial-gradient(50% 50% at 50% 50%, #63386a 0%, #310438 100%); */
    background-size: "cover";
  }
  
  .container {
    padding: 0rem 1rem;
    width: 100%;
  }

  .white-container {
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-container {
    height: 100%;
    background-image: url("/src/assets/vb-pim-inloggning.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .title {
    color: #626262;
    font-size: 30px;
    letter-spacing: -0.04em;
    margin-bottom: 2rem !important;
  }
  
  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
  }

  @media screen and (min-width: 600px) {
    .container {
      padding: 0rem;
      max-width: 400px;
      width: 100%;
    }
  }
@font-face {
    font-family: 'HK Grotesk Bold';
    src: url('./hk-grotesk.bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'HK Grotesk Regular';
    src: url('./hk-grotesk.regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'HK Grotesk SemiBold';
    src: url('./hk-grotesk.semibold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
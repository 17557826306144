.tableContainer {
    overflow-x: auto;
    margin: 1rem 0;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9rem;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .table th {
    background-color: #f8fafc;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  
  .table tr:hover {
    background-color: #f1f5f9;
  }

  .selectedRow {
    background-color: #e2e8f0;
  }
  
  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    opacity: 0;
    transition: opacity 0.2s;
  }
  
  .resizer:hover,
  .resizer.isResizing {
    opacity: 1;
  }
  
  @media (max-width: 640px) {
    .table {
      font-size: 0.8rem;
    }
  
    .table th,
    .table td {
      padding: 0.5rem;
    }
  }

  .tableControls {
    margin-bottom: 1rem;
    position: relative;
  }


  .columnSelector {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    z-index: 10;
  }

  .columnToggle {
    display: block;
    margin-bottom: 0.5rem;
  }

  .columnToggle input {
    margin-right: 0.5rem;
  }

  .defaultColumns {
    table-layout: auto;
    width: 100%;
  }

  .equalColumns {
    table-layout: fixed;
    width: 100%;
  }

  .equalColumns th,
  .equalColumns td {
    width: 1%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tableWrapper {
    overflow-x: auto;
  }
  
  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: rgba(25, 81, 120, 0.9);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }
  
  .resizer.isResizing {
    background: rgba(25, 81, 120, 0.7);
  }
  
  @media (hover: hover) {
    .resizer {
      opacity: 0;
    }
  
    *:hover > .resizer {
      opacity: 1;
    }
  }

  .footer {
    padding: 0.25rem 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .noData {
    text-align: center;
  }

  .headerContent {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .sortIcon {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    margin-top: 3px;
  }

  .headerCell:hover .sortIcon {
    opacity: 1;
  }

  .headerRow {
    position: relative;
  }

  .columnSelectorButton {
    position: absolute;
    right: 8px;
    top: 2px;
    z-index: 10;
    padding: 0; 
    margin: 0; 
    width: 16px !important; 
    height: 16px !important;
  }

  .optionsList-show {
    opacity: 1 !important; /* Fully visible */
    visibility: visible !important; /* Make it visible */
  }

  .optionsList {
    background-color: white; /* Set a white background for contrast */
    border-radius: 4px; /* Rounded corners for a softer look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect for depth */
    z-index: 1000; /* Ensure it appears above other elements */
    padding: 8px 0; /* Padding for spacing */
    min-width: 150px; /* Minimum width for the dropdown */
    transition: opacity 0.2s ease; /* Smooth transition for appearance */
}

/* Show the options list when it is active */


/* Style for each option */
.optionsList div {
    padding: 8px 16px; /* Padding for each option */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.2s ease; /* Smooth background transition */
}

/* Change background color on hover */
.optionsList div:hover {
    background-color: rgba(0, 0, 0, 0.05); /* Light gray background on hover */
}
